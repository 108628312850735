import { section } from "@/glow";
import { animateContactForm, shouldAnimate } from "@/lib/animation";

section("contact-form", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateContactForm = animateContactForm(this.container);
    }
  },

  onUnload() {
    this.animateContactForm?.destroy();
  },
});
