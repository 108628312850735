import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  controls: ".product-tabs__tab-buttons",
  items: ".product-tabs__tab-list-wrapper",
  accordionItems: ".accordion",
};

export default node => {
  // Add the animation delay offset variables
  delayOffset(node, [selectors.controls, selectors.items]);
  delayOffset(node, [selectors.accordionItems]);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
