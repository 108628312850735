import { qsa, listen } from "@fluorescent/dom";
import { emit } from "@/glow/events";

import { AnimateProductItem } from "@/lib/animation";

const selectors = {
  item: ".product-item",
  itemInner: ".product-item__inner",
  quickViewButton: ".show-product-quickview",
};

export default function ProductItem(container) {
  const items = qsa(selectors.item, container);
  if (!items.length) return;

  // Add z-index for quick-buy overlap
  items.forEach((item, i) =>
    item.style.setProperty("--z-index-item", items.length - i)
  );

  const productItemAnimations = AnimateProductItem(items);

  const quickViewButtons = qsa(selectors.quickViewButton, container);

  const events = [
    listen(quickViewButtons, "click", e => {
      e.preventDefault();
      e.stopPropagation();
      const linkEl = e.currentTarget;
      const url = linkEl.getAttribute("href");

      emit("quick-view:open", null, {
        productUrl: url,
      });
    }),
  ];

  const unload = () => {
    productItemAnimations.destroy();
    events.forEach(unsubscribe => unsubscribe());
  };

  return { unload };
}
