import { section } from "@/glow";
import { animateSalesBanner, shouldAnimate } from "@/lib/animation";

section("sales-banner", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateSalesBanner = animateSalesBanner(this.container);
    }
  },

  onUnload() {
    this.animateSalesBanner?.destroy();
  },
});
