import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  headerItems: ".animation--section-introduction > *",
  articleItem: ".article-item",
};

export default node => {
  delayOffset(node, [selectors.headerItems, selectors.articleItem]);

  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
