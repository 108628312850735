import { qs, listen } from "@fluorescent/dom";

const selectors = {
  moreButton: "[data-more-media]",
  moreBar: "[data-more-media-bar]",
  productMedia: "[data-product-media]",
};

const states = {
  closed: "closed",
  beforeOpen: "beforeOpen",
  opening: "opening",
  open: "open",
};

const moreMedia = node => {
  const moreButton = qs(selectors.moreButton, node);

  if (!moreButton) {
    return;
  }

  const moreBar = qs(selectors.moreBar, node);
  const productMedia = qs(selectors.productMedia, node);
  const initialAR = parseFloat(window.getComputedStyle(productMedia).aspectRatio);


  let isOpen = false;

  const updateText = open => {
    moreButton.innerHTML =
      moreButton.dataset[open ? "langLessMedia" : "langMoreMedia"];
  };

  const close = () => {
    if (!isOpen) return;

    if (!isFinite(initialAR)) {
      // If AR is NaN it's either 'auto' or unsupported by the browser,
      // in which case we can't transition it. Instead, jump directly to
      // the final state.
      productMedia.dataset.productMedia = states.closed;
      isOpen = false;
      updateText(false);
      return;
    }

    productMedia.dataset.productMedia = states.opening;

    window.requestAnimationFrame(() => {
      const transitionEnd = listen(productMedia, "transitionend", () => {
        transitionEnd();
        productMedia.dataset.productMedia = states.closed;
        isOpen = false;
      });

      productMedia.dataset.productMedia = states.beforeOpen;
      updateText(false);
    });
  };

  const open = () => {
    if (isOpen) return;

    if (!isFinite(initialAR)) {
      // If AR is NaN it's either 'auto' or unsupported by the browser,
      // in which case we can't transition it. Instead, jump directly to
      // the final state.
      productMedia.dataset.productMedia = states.open;
      isOpen = true;
      updateText(true);
      return;
    }

    productMedia.dataset.productMedia = states.beforeOpen;

    window.requestAnimationFrame(() => {
      const { width } = productMedia.getBoundingClientRect();
      const { scrollHeight } = productMedia;
      const gridGap = parseInt(
        window.getComputedStyle(productMedia).rowGap,
        10
      );
      const barBottom = parseInt(window.getComputedStyle(moreBar).bottom, 10);

      const openAspectRatio = width / (scrollHeight - gridGap - barBottom);

      productMedia.style.setProperty(
        "--overflow-gallery-aspect-ratio-open",
        openAspectRatio
      );

      const transitionEnd = listen(productMedia, "transitionend", e => {
        if (e.target !== productMedia) {
          // Ignore any bubbled up event from image load transitions, etc.
          return;
        }

        transitionEnd();
        productMedia.dataset.productMedia = states.open;
        isOpen = true;
      });

      productMedia.dataset.productMedia = states.opening;

      updateText(true);
    });
  };

  const clickListener = listen(moreButton, "click", () => {
    isOpen ? close() : open();
  });

  const resizeListener = listen(window, "resize", () => close());

  const events = [clickListener, resizeListener];

  const unload = () => {
    events.forEach(evt => evt());
  };

  return { unload };
};

export default moreMedia;
