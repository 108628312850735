import { qsa } from "@fluorescent/dom";

import { section } from "@/glow";
import {
  intersectionWatcher,
  animateImageHeroSplit,
  shouldAnimate,
} from "@/lib/animation";

section("image-hero-split", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      // Setup animations per item
      qsa(".animation--item", this.container).forEach(item =>
        animateImageHeroSplit(item)
      );
    }

    this.observer = intersectionWatcher(this.container);
  },

  onUnload() {
    this.playButtons && this.playButtons.forEach(button => button.unload());
    this.observer?.destroy();
  },
});
