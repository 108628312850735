import { qsa } from "@fluorescent/dom";
import { section } from "@/glow";

import { wrapIframes, wrapTables } from "@/lib/rte";
import { backgroundVideoHandler } from "@/lib/a11y";
import accordions from "@/lib/accordions";
import { animatePage, shouldAnimate } from "@/lib/animation";

const selectors = {
  video: ".about__block-video",
};

section("page", {
  onLoad() {
    const videos = qsa(selectors.video, this.container);
    this.videoHandlers = [];

    if (videos.length) {
      videos.forEach(video => {
        this.videoHandlers.push(backgroundVideoHandler(video.parentNode));
      });
    }

    this.accordions = accordions(qsa(".accordion", this.container));
    wrapIframes(qsa("iframe", this.container));
    wrapTables(qsa("table", this.container));

    if (shouldAnimate(this.container)) {
      this.animatePage = animatePage(this.container);
    }
  },

  onUnload() {
    this.accordions.unload();
    this.videoHandlers.forEach(handler => handler());
    this.animatePage?.destroy();
  },
});
