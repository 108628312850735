import { qsa } from "@fluorescent/dom";

import { section } from "@/glow";
import playButtonBlock from "@/lib/play-button-block";
import { animateImageWithTextSplit, shouldAnimate } from "@/lib/animation";

const selectors = {
  playButtonVideo: "[data-play-button-block-video]",
  playButtonBlock: ".play-button-block",
};

section("image-with-text-split", {
  onLoad() {
    const playButtonVideos = qsa(selectors.playButtonVideo, this.container);

    if (playButtonVideos.length) {
      this.playButtons = playButtonVideos.map(block =>
        playButtonBlock(block.closest(selectors.playButtonBlock))
      );
    }

    if (shouldAnimate(this.container)) {
      this.animateImageWithTextSplit = animateImageWithTextSplit(
        this.container
      );
    }
  },

  onUnload() {
    this.playButtons && this.playButtons.forEach(button => button.unload());
    this.animateImageWithTextSplit?.destroy();
  },
});
