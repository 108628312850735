import { qs, qsa } from "@fluorescent/dom";

import { section } from "@/glow";
import { backgroundVideoHandler } from "@/lib/a11y";
import playButtonBlock from "@/lib/play-button-block";
import { animateVideoHero, shouldAnimate } from "@/lib/animation";
import autoPlay from "../lib/autoPlay";

const selectors = {
  video: ".video-hero__video",
  playButtonVideo: "[data-play-button-block-video]",
  playButtonBlock: ".play-button-block",
};

section("video-hero", {
  videoHandler: null,
  onLoad() {
    const playButtonVideos = qsa(selectors.playButtonVideo, this.container);
    const video = qsa(selectors.video, this.container);

    if (playButtonVideos.length) {
      this.playButtons = playButtonVideos.map(block =>
        playButtonBlock(block.closest(selectors.playButtonBlock))
      );
    }

    if (video) {
      this.videoHandler = backgroundVideoHandler(this.container);
      autoPlay(video);
    }

    if (shouldAnimate(this.container)) {
      this.animateVideoHero = animateVideoHero(this.container);
    }
  },

  onUnload() {
    this.playButtons && this.playButtons.forEach(button => button.unload());
    this.videoHandler && this.videoHandler();
    this.animateVideoHero?.destroy();
  },
});
