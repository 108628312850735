import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  sectionBlockItems: ".animation--section-introduction > *",
  controls: ".animation--controls",
  items: ".animation--item",
};

export default node => {
  const delayItems = [
    selectors.sectionBlockItems,
    selectors.controls,
    selectors.items,
  ];

  // Add the animation delay offset variables
  delayOffset(node, delayItems);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
