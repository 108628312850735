import { section } from "@/glow";
import { animateNewsletter, shouldAnimate } from "@/lib/animation";

section("newsletter", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateNewsletter = animateNewsletter(this.container);
    }
  },

  onUnload() {
    this.animateNewsletter?.destroy();
  },
});
