import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  headerItems: ".animation--blog-header > *",
  articleItem: ".article-item",
  pagination: ".blog__pagination",
};

export default node => {
  delayOffset(node, [
    selectors.headerItems,
    selectors.articleItem,
    selectors.pagination,
  ]);

  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
