import { section } from "@/glow";
import { animateMultiColumn, shouldAnimate } from "@/lib/animation";

section("multi-column", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateMultiColumn = animateMultiColumn(this.container);
    }
  },

  onUnload() {
    this.animateMultiColumn?.destroy();
  },
});
