import { formatMoney } from "@/glow";
import { qsa } from "@fluorescent/dom";

const {
  strings: { products: strings },
} = window.theme;

const selectors = {
  price: "[data-price]",
  comparePrice: "[data-compare-price]",
};

export default function (container, variant) {
  const price = qsa(selectors.price, container);
  const comparePrice = qsa(selectors.comparePrice, container);
  const unavailableString = strings.product.unavailable;

  if (!variant) {
    price.forEach(el => (el.innerHTML = unavailableString));
    comparePrice.forEach(el => (el.innerHTML = ""));
    return;
  }

  price.forEach(el => (el.innerHTML = formatMoney(variant.price)));
  comparePrice.forEach(
    el =>
      (el.innerHTML =
        variant.compare_at_price > variant.price
          ? formatMoney(variant.compare_at_price)
          : "")
  );
}
