import { qs, qsa, listen } from "@fluorescent/dom";

import { section } from "@/glow";
import { backgroundVideoHandler } from "@/lib/a11y";
import { emit } from "@/glow/events";
import { animateCompleteTheLook, shouldAnimate } from "@/lib/animation";

const selectors = {
  video: "video",
  quickViewTrigger: "[data-quick-view-trigger]",
};

section("complete-the-look", {
  videoHandler: null,

  onLoad() {
    const video = qs(selectors.video, this.container);
    if (video) {
      this.videoHandler = backgroundVideoHandler(this.container);
    }

    this.events = [
      listen(qsa(selectors.quickViewTrigger, this.container), "click", e => {
        const { productUrl } = e.target.dataset;
        if (!productUrl) return;

        emit("quick-view:open", null, {
          productUrl: productUrl,
        });
      }),
    ];

    if (shouldAnimate(this.container)) {
      this.animateCompleteTheLook = animateCompleteTheLook(this.container);
    }
  },

  onUnload() {
    this.videoHandler && this.videoHandler();
    this.events.forEach(unsubscribe => unsubscribe());
    this.animateCompleteTheLook?.destroy();
  },
});
