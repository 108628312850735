import { delayOffset, intersectionWatcher } from "@/lib/animation";

const selectors = {
  items: ".collapsible-row-list__inner > *",
};

export default node => {
  // Add the animation delay offset variables
  delayOffset(node, [selectors.items]);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
