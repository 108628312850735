import { qs, listen } from "@fluorescent/dom";

const selectors = {
  playButton: "[data-play-button-block]",
  playButtonVideoContainer: "[data-play-button-block-video-container]",
  photoSwipeElement: ".pswp",
  video: ".play-button-block-video",
};

const { icons } = window.theme;

const playButton = node => {
  let photoSwipeInstance;
  const playButton = qs(selectors.playButton, node);
  const videoHtml = qs(selectors.playButtonVideoContainer, node).outerHTML;

  import(flu.chunks.photoswipe); // Load this ahead of needing

  const events = [
    listen(playButton, "click", () => {
      import(flu.chunks.photoswipe).then(
        ({ PhotoSwipeLightbox, PhotoSwipe }) => {
          photoSwipeInstance = new PhotoSwipeLightbox({
            dataSource: [{ html: videoHtml }],
            pswpModule: PhotoSwipe,
            mainClass: "pswp--video-lightbox",
            closeSVG: icons.close,
            arrowPrev: false,
            arrowNext: false,
            zoom: false,
            counter: false,
          });
          photoSwipeInstance.init();
          photoSwipeInstance.loadAndOpen();
          photoSwipeInstance.on("bindEvents", () => {
            const instanceVideo = qs(
              selectors.video,
              photoSwipeInstance.pswp.container
            );
            instanceVideo.play();
          });
        }
      );
    }),
  ];

  const unload = () => {
    events.forEach(unsubscribe => unsubscribe());
    photoSwipeInstance && photoSwipeInstance.destroy();
  };

  return { unload };
};

export default playButton;
