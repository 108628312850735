import { section } from "@/glow";
import { animateNewsletterCompact, shouldAnimate } from "@/lib/animation";

section("newsletter-compact", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateNewsletterCompact = animateNewsletterCompact(this.container);
    }
  },

  onUnload() {
    this.animateNewsletterCompact?.destroy();
  },
});
