import { intersectionWatcher } from "@/lib/animation";

export default node => {
  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
