import { section } from "@/glow";
import { animateGrid, shouldAnimate } from "@/lib/animation";

section("grid", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateGrid = animateGrid(this.container);
    }
  },

  onUnload() {
    this.animateGrid?.destroy();
  },
});
