import { section } from "@/glow";
import { animateCollectionBanner, shouldAnimate } from "@/lib/animation";

section("collection-banner", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateCollectionBanner = animateCollectionBanner(this.container);
    }
  },

  onUnload() {
    this.animateCollectionBanner?.destroy();
  },
});
