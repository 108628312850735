import { qs } from "@fluorescent/dom";

export default function PredictiveSearch(resultsContainer) {
  const settings = qs("[data-search-settings]", document);
  const { limit, show_articles, show_collections, show_pages } = JSON.parse(
    settings.innerHTML
  );
  const cachedResults = {};

  // Build out type query string
  let types = ["product"];

  if (show_articles) {
    types.push("article");
  }

  if (show_collections) {
    types.push("collection");
  }

  if (show_pages) {
    types.push("page");
  }

  function renderSearchResults(resultsMarkup) {
    resultsContainer.innerHTML = resultsMarkup;
  }

  function getSearchResults(searchTerm) {
    const queryKey = searchTerm.replace(" ", "-").toLowerCase();

    // Render result if it appears within the cache
    if (cachedResults[`${queryKey}`]) {
      renderSearchResults(cachedResults[`${queryKey}`]);
      return;
    }

    fetch(
      `${window.theme.routes.predictive_search_url}?q=${encodeURIComponent(
        searchTerm
      )}&${encodeURIComponent("resources[type]")}=${types.join(
        ","
      )}&${encodeURIComponent(
        "resources[limit]"
      )}=${limit}&section_id=predictive-search`
    )
      .then(response => {
        if (!response.ok) {
          const error = new Error(response.status);
          throw error;
        }

        return response.text();
      })
      .then(text => {
        let resultsMarkup = new DOMParser()
          .parseFromString(text, "text/html")
          .querySelector("#shopify-section-predictive-search").innerHTML;

        // Cache results
        cachedResults[queryKey] = resultsMarkup;
        renderSearchResults(resultsMarkup);
      })
      .catch(error => {
        throw error;
      });
  }

  return { getSearchResults };
}
