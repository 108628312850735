import { delayOffset, intersectionWatcher } from "@/lib/animation";

const selectors = {
  textContent: ".password__text-container-inner > *",
};

export default node => {
  // Add the animation delay offset variables
  delayOffset(node, [selectors.textContent], 3);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
