import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  heading: ".list-collections__heading",
  productItems: ".animation--item",
};

export default node => {
  delayOffset(node, [selectors.heading, selectors.productItems]);
  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer.destroy();
    },
  };
};
