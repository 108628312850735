import { add, listen, qs, qsa, remove } from "@fluorescent/dom";

export default function Media(node) {
  if (!node) return;
  const { Shopify, YT } = window;
  const elements = qsa("[data-interactive]", node);

  if (!elements.length) return;

  const acceptedTypes = ["video", "model", "external_video"];
  let activeMedia = null;
  let featuresLoaded = false;
  let instances = {};

  if (featuresLoaded) {
    elements.forEach(initElement);
  }

  window.Shopify.loadFeatures(
    [
      {
        name: "model-viewer-ui",
        version: "1.0",
      },
      {
        name: "shopify-xr",
        version: "1.0",
      },
      {
        name: "video-ui",
        version: "1.0",
      },
    ],
    () => {
      featuresLoaded = true;

      if ("YT" in window && Boolean(YT.loaded)) {
        elements.forEach(initElement);
      } else {
        window.onYouTubeIframeAPIReady = function () {
          elements.forEach(initElement);
        };
      }
    }
  );

  function initElement(el) {
    const { mediaId, mediaType } = el.dataset;
    if (!mediaType || !acceptedTypes.includes(mediaType)) return;

    if (Object.keys(instances).includes(mediaId)) return;

    let instance = {
      id: mediaId,
      type: mediaType,
      container: el,
      media: el.children[0],
    };

    switch (instance.type) {
      case "video":
        instance.player = new Shopify.Plyr(instance.media, {
          loop: { active: el.dataset.loop == "true" },
        });
        break;

      case "external_video": {
        instance.player = new YT.Player(instance.media);

        // This overlay makes it possible to swipe video embeds in carousels
        const overlay = qs(".external-video-overlay", el);
        if (overlay) {
          listen(overlay, "click", e => {
            e.preventDefault();
            // in some situations the iframe-js-api can't communicate and this is undef,
            // in this case lets faily quietly and remove the overlay (it won't come back)
            if (instance.player?.playVideo) {
              instance.player.playVideo();
            }
            add(overlay, "hidden");
          });
          instance.player.addEventListener("onStateChange", event => {
            if (event.data === 2) {
              remove(overlay, "hidden");
            }
          });
        }

        break;
      }

      case "model":
        instance.viewer = new Shopify.ModelViewerUI(qs("model-viewer", el));

        listen(qs(".model-poster", el), "click", e => {
          e.preventDefault();
          playModel(instance);
        });
        break;
    }

    instances[mediaId] = instance;

    if (instance.player) {
      if (instance.type === "video") {
        instance.player.on("playing", () => {
          pauseActiveMedia(instance);
          activeMedia = instance;
        });
      } else if (instance.type === "external_video") {
        instance.player.addEventListener("onStateChange", event => {
          if (event.data === 1) {
            pauseActiveMedia(instance);
            activeMedia = instance;
          }
        });
      }
    }
  }

  function playModel(instance) {
    pauseActiveMedia(instance);
    instance.viewer.play();
    add(instance.container, "model-active");
    activeMedia = instance;

    setTimeout(() => {
      qs("model-viewer", instance.container).focus();
    }, 300);
  }

  function pauseActiveMedia(instance) {
    if (!activeMedia || instance == activeMedia) return;

    if (activeMedia.player) {
      if (activeMedia.type === "video") {
        activeMedia.player.pause();
      } else if (activeMedia.type === "external_video") {
        activeMedia.player.pauseVideo();
      }

      activeMedia = null;
      return;
    }

    if (activeMedia.viewer) {
      remove(activeMedia.container, "model-active");
      activeMedia.viewer.pause();
      activeMedia = null;
    }
  }

  return { pauseActiveMedia };
}
