import { add, qs, qsa, remove } from "@fluorescent/dom";
import { Ajaxinate } from "ajaxinate";

import { section } from "@/glow";
import ProductItem from "@/lib/product-item";
import filterHandler from "@/lib/filters/filter-handler";
import filterDrawer from "@/lib/filters/filter-drawer";
import filterBar from "@/lib/filters/filter-bar";
import { emit } from "@/glow/events";
import { animateCollection, shouldAnimate } from "@/lib/animation";

const selectors = {
  infiniteScrollContainer: ".collection__infinite-container",
  infiniteScrollTrigger: ".collection__infinite-trigger",
  partial: "[data-partial]",
  filterDrawer: "[data-filter-drawer]",
  filterBar: "[data-filter-bar]",
  loader: ".collection__loading",
  paginationItemCount: "[data-pagination-item-count]",
  productItems: ".product-item",
};

const classes = {
  active: "is-active",
  hideProducts: "animation--collection-products-hide",
};

const { strings } = window.theme;

section("collection", {
  infiniteScroll: null,

  onLoad() {
    const { collectionItemCount, paginationType } = this.container.dataset;

    if (!parseInt(collectionItemCount)) return;

    this.filterDrawerEl = qs(selectors.filterDrawer, this.container);
    this.filterbarEl = qs(selectors.filterBar, this.container);
    this.paginationItemCount = qs(
      selectors.paginationItemCount,
      this.container
    );

    if (this.filterDrawerEl || this.filterbarEl) {
      this.partial = qs(selectors.partial, this.container);

      this.filterDrawer = filterDrawer(this.container);
      this.filterBar = filterBar(this.container);
      this.filterHandler = filterHandler({
        container: this.container,
        renderCB: this._renderView.bind(this),
      });
    }

    // Ininite scroll
    this.paginationType = paginationType;
    this.paginated = this.paginationType === "paginated";

    this.infiniteScrollTrigger = qs(
      selectors.infiniteScrollTrigger,
      this.container
    );

    if (!this.paginated) {
      this._initInfiniteScroll();
    }

    this.productItem = ProductItem(this.container);

    if (shouldAnimate(this.container)) {
      this.animateCollection = animateCollection(this.container);
    }
  },

  _initInfiniteScroll() {
    const infiniteScrollOptions = {
      container: selectors.infiniteScrollContainer,
      pagination: selectors.infiniteScrollTrigger,
      loadingText: "Loading...",
      callback: () => {
        this.productItem && this.productItem.unload();
        this.productItem = ProductItem(this.container);
        this.animateCollection?.infiniteScrollReveal();
        this._updatePaginationCount();

        emit("collection:updated");
      },
    };

    if (this.paginationType === "click") {
      infiniteScrollOptions.method = "click";
    }

    this.infiniteScroll = new Ajaxinate(infiniteScrollOptions);
  },

  _updatePaginationCount() {
    const productItemCount = qsa(selectors.productItems, this.container).length;
    const viewing = strings.pagination.viewing
      .replace("{{ of }}", `1-${productItemCount}`)
      .replace("{{ total }}", this.partial.dataset.collectionProductsCount);
    this.paginationItemCount.innerHTML = `${viewing} ${strings.pagination.products}`;
  },

  _renderView(searchParams, updateHistory = true) {
    const url = `${window.location.pathname}?section_id=${this.container.dataset.sectionId}&${searchParams}`;
    const loading = qs(selectors.loader, this.container);

    add(this.partial, classes.hideProducts);
    add(loading, classes.active);

    fetch(url)
      .then(res => res.text())
      .then(res => {
        if (updateHistory) {
          this._updateURLHash(searchParams);
        }

        const doc = new DOMParser().parseFromString(res, "text/html");
        const updatedPartial = qs(selectors.partial, doc);
        this.partial.innerHTML = updatedPartial.innerHTML;
        this.partial.dataset.collectionProductsCount =
          updatedPartial.dataset.collectionProductsCount;
        this.animateCollection?.updateContents();

        if (!this.paginated && this.infiniteScrollTrigger) {
          this.infiniteScrollTrigger.innerHTML = "";

          this._initInfiniteScroll();
        }

        this.filterDrawer && this.filterDrawer.renderFilters(doc);
        this.filterBar && this.filterBar.renderFilters(doc);
        this.productItem && this.productItem.unload();
        this.productItem = ProductItem(this.container);
        this.paginationItemCount = qs(
          selectors.paginationItemCount,
          this.container
        );

        remove(loading, classes.active);
        emit("collection:updated");
      });
  },

  _updateURLHash(searchParams) {
    history.pushState(
      { searchParams },
      "",
      `${window.location.pathname}${searchParams && "?".concat(searchParams)}`
    );
  },

  onUnload() {
    this.infiniteScroll && this.infiniteScroll.destroy();
    this.filterHandler && this.filterHandler.unload();

    this.filterDrawer && this.filterDrawer.unload();
    this.filterBar && this.filterBar.unload();
    this.filtering && this.filtering.unload();

    this.productItem && this.productItem.unload();

    this.animateCollection?.destroy();
  },
});
