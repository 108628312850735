import { delayOffset, intersectionWatcher } from "@/lib/animation";

const selectors = {
  gridItems: ".grid-item",
};

export default node => {
  delayOffset(node, [selectors.gridItems]);
  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer.destroy();
    },
  };
};
