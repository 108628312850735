import { section } from "@/glow";
import { animateListCollections, shouldAnimate } from "@/lib/animation";

section("list-collections", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateListCollections = animateListCollections(this.container);
    }
  },

  onUnload() {
    this.animateListCollections?.destroy();
  },
});
