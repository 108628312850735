import { qs, qsa, listen, contains } from "@fluorescent/dom";

import { section } from "@/glow";
import { on, emit } from "@/glow/events";
import { prefersReducedMotion } from "@/lib/a11y";
import {
  intersectionWatcher,
  delayOffset,
  shouldAnimate,
} from "@/lib/animation";

const selectors = {
  slide: "[data-slide]",
  swiper: ".swiper",
  navigationPrev: ".slideshow-navigation__navigation-button--previous",
  navigationNext: ".slideshow-navigation__navigation-button--next",
  navigationDots: ".slideshow-navigation__dots",
  navigationDot: ".slideshow-navigation__dot",
  navigationLoader: ".slideshow-navigation__dot-loader",
  activeDot: "slideshow-navigation__dot--active",
  animatableItems: ".animation--section-blocks > *",
};

section("slideshow", {
  onLoad() {
    this.events = [];
    this.enableAutoplay = this.container.dataset.enableAutoplay;
    this.autoplayDuration = this.container.dataset.autoplay;
    this.slideshow = null;
    this.slideshowContainer = qs(selectors.swiper, this.container);
    this.slides = qsa(selectors.slide, this.container);
    this.events.push(
      listen(this.container, "focusin", () => this.handleFocus())
    );

    if (shouldAnimate(this.container)) {
      this.slideAnimations = this.slides.map(slide =>
        delayOffset(slide, [selectors.animatableItems], 3)
      );
      this.observer = intersectionWatcher(this.container);
    }

    if (this.slides.length > 1) {
      import(flu.chunks.swiper).then(
        ({
          Swiper,
          Navigation,
          Autoplay,
          Pagination,
          EffectFade,
          EffectCreative,
        }) => {
          const swiperOptions = {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            grabCursor: true,
            effect: "fade",
            fadeEffect: {
              crossFade: false,
            },
            watchSlidesProgress: true,
            loop: true,
            navigation: {
              nextEl: selectors.navigationNext,
              prevEl: selectors.navigationPrev,
            },
            pagination: {
              el: selectors.navigationDots,
              clickable: true,
              bulletActiveClass: selectors.activeDot,
              bulletClass: "slideshow-navigation__dot",
              renderBullet: (_, className) => `
                <button class="${className}" type="button">
                  <div class="slideshow-navigation__dot-loader"></div>
                </button>`,
            },
            on: {
              afterInit: () => {
                this.handleBulletLabels();
              },
              slideChangeTransitionEnd() {
                const slideEls = this.slides;
                setTimeout(function () {
                  slideEls.forEach(slide => {
                    slide.toggleAttribute(
                      "inert",
                      !slide.classList.contains("swiper-slide-active")
                    );
                  });
                }, 50);
              },
            },
          };

          if (this.enableAutoplay === "true") {
            swiperOptions.modules.push(Autoplay);
            swiperOptions.autoplay = {
              delay: this.autoplayDuration,
              disableOnInteraction: false,
            };
          }

          if (!prefersReducedMotion()) {
            swiperOptions.modules.push(EffectFade);
            swiperOptions.effect = "fade";
            swiperOptions.fadeEffect = {
              crossFade: false,
            };
          } else {
            swiperOptions.modules.push(EffectCreative);
            swiperOptions.effect = "creative";
            swiperOptions.creativeEffect = {
              prev: {
                opacity: 0.99,
              },
              next: {
                opacity: 1,
              },
            };
          }

          this.slideshow = new Swiper(this.slideshowContainer, swiperOptions);
          emit("slideshow:initialized");
        }
      );
    }
  },

  handleFocus() {
    if (contains(document.body, "user-is-tabbing")) {
      this.slideshow.autoplay.stop();
    }
  },

  handleBulletLabels() {
    const bullets = qsa(selectors.navigationDot, this.container);

    bullets.forEach((bullet, index) => {
      const associatedSlide = this.slides[index];
      const { bulletLabel } = associatedSlide.dataset;
      bullet.setAttribute("aria-label", bulletLabel);
    });
  },

  handleBlockSelect(slideIndex) {
    this.slideshow.slideTo(parseInt(slideIndex, 10));
    this.slideshow.autoplay.stop();

    // Pause all loading animations
    qsa(selectors.navigationLoader, this.container).forEach(loader => {
      loader.style.animationPlayState = "paused";
    });
  },

  handleBlockDeselect() {
    this.slideshow?.autoplay.start();

    // Resume all loading animations
    qsa(selectors.navigationLoader, this.container).forEach(loader => {
      loader.style.animationPlayState = "running";
    });
  },

  onBlockSelect({ target }) {
    const { slide } = target.dataset;

    if (this.slideshow) {
      this.handleBlockSelect(slide);
    } else {
      // Listen for initalization if slideshow does not exist
      this.events.push(
        on("slideshow:initialized", () => {
          this.handleBlockSelect(slide);
        })
      );
    }
  },

  onBlockDeselect() {
    if (this.slideshow) {
      this.handleBlockDeselect();
    } else {
      // Listen for initalization if slideshow does not exist
      this.events.push(
        on("slideshow:initialized", () => {
          this.handleBlockDeselect();
        })
      );
    }
  },

  onUnload() {
    this.slideshow?.destroy();
    this.events.forEach(unsubscribe => unsubscribe());
    this.observer?.destroy();
  },
});
