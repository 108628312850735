import Delegate from "ftdomdelegate";
import { remove, qs, add } from "@fluorescent/dom";

import { section, cart } from "@/glow";
import { on } from "@/glow/events";
import { makeRequest } from "@/lib/xhr";
import QuantityButtons from "@/lib/quantity-buttons";
import CartNoteToggle from "@/lib/cart-note-toggle";
import FreeShippingBar from "@/lib/free-shipping-bar";
import CrossSells from "@/lib/cross-sells";

const selectors = {
  cartError: ".cart__form-item-error",
  cartNoteTrigger: "[data-order-note-trigger]",
  cartUpdateButton: ".cart__update",
  quantityInput: ".cart .quantity-input__input",
  quantityItem: "[data-input-item]",
  freeShippingBar: "[data-free-shipping-bar]",
  crossSells: "[data-cross-sells]",
};

const classes = {
  updatingQuantity: "has-quantity-update",
  removed: "is-removed",
};

section("cart", {
  onLoad() {
    const cartNoteTrigger = qs(selectors.cartNoteTrigger, this.container);
    const freeShippingBar = qs(selectors.freeShippingBar, this.container);

    if (freeShippingBar) {
      FreeShippingBar(freeShippingBar);
    }

    this._initCrossSells();

    if (cartNoteTrigger) this.cartNoteToggle = CartNoteToggle(this.container);
    this.quantityButtons = QuantityButtons(this.container);

    // Events are all on events trigger by other components / functions
    this.events = [
      on("cart:updated", () => this.refreshCart()),
      on("cart:error", (_, { key, errorMessage }) => {
        this.handleErrorMessage(key, errorMessage);
      }),
      on(["quantity-update:subtract", "quantity-update:add"], (_, { key }) => {
        this.handleQuantityUpdate(key);
      }),
      on("quantity-update:remove", (_, { key }) => {
        this.handleItemRemoval(key);
      }),
    ];

    // Delegate handles all click events due to rendering different content
    // within cart
    this.delegate = new Delegate(this.container);
    this.delegate.on("change", selectors.quantityInput, e =>
      this.handleQuantityInputChange(e)
    );
  },

  refreshCart() {
    const url = `${theme.routes.cart.base}?section_id=main-cart`;

    makeRequest("GET", url).then(response => {
      let container = document.createElement("div");
      container.innerHTML = response;

      this.container.innerHTML = container.innerHTML;

      if (window.Shopify?.StorefrontExpressButtons) {
        window.Shopify.StorefrontExpressButtons.initialize();
      }

      const freeShippingBar = qs(selectors.freeShippingBar, this.container);

      if (freeShippingBar) {
        FreeShippingBar(freeShippingBar);
      }

      this._initCrossSells();
    });
  },

  handleErrorMessage(key) {
    const item = qs(`[data-key="${key}"]`, this.container);
    remove(qs(selectors.cartError, item), "hidden");
    remove(item, classes.updatingQuantity);
  },

  handleQuantityInputChange({ target }) {
    const item = target.closest(selectors.quantityItem);
    const { key } = item.dataset;

    cart.updateItem(key, target.value);
    this.handleQuantityUpdate(key);
  },

  handleQuantityUpdate(key) {
    const item = qs(`[data-key="${key}"]`, this.container);
    add(item, classes.updatingQuantity);
  },

  handleItemRemoval(key) {
    const item = qs(`[data-key="${key}"]`, this.container);
    add(item, classes.removed);
    add(item, classes.updatingQuantity);
  },

  _initCrossSells() {
    const crossSells = qs(selectors.crossSells, this.container);

    if (crossSells) {
      this.crossSells = CrossSells(crossSells);
    }
  },

  onUnload() {
    this.events.forEach(unsubscribe => unsubscribe());
    this.quantityButtons.unload();
    this.cartNoteToggle?.unload();
  },
});
