import { qsa } from "@fluorescent/dom";
import { section } from "@/glow";

import accordions from "@/lib/accordions";
import { wrapIframes, wrapTables } from "@/lib/rte";

section("contact", {
  onLoad() {
    this.accordions = accordions(qsa(".accordion", this.container));

    wrapIframes(qsa("iframe", this.container));
    wrapTables(qsa("table", this.container));
  },

  onUnload() {
    this.accordions.unload();
  },
});
