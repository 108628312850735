import { delayOffset, intersectionWatcher } from "@/lib/animation";

const selectors = {
  pageItems: ".page-section__inner > *",
};

export default node => {
  // Add the animation delay offset variables
  delayOffset(node, [selectors.pageItems]);

  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer.forEach(observer => observer.destroy());
    },
  };
};
