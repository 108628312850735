import { section } from "@/glow";
import { animatePromotionBar, shouldAnimate } from "@/lib/animation";

section("promotion-bar", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animatePromotionBar = animatePromotionBar(this.container);
    }
  },

  onUnload() {
    this.animatePromotionBar?.destroy();
  },
});
