import { slideDown, slideUp, slideStop, isVisible } from "slide-anim";

import { section } from "@/glow";
import { listen, qsa, qs } from "@fluorescent/dom";
import { animateCollapsibleRowList, shouldAnimate } from "@/lib/animation";

const selectors = {
  itemTrigger: ".collapsible-row-list-item__trigger",
};

section("collapsible-row-list", {
  onLoad() {
    this.items = qsa(selectors.itemTrigger, this.container);
    this.clickHandlers = listen(this.items, "click", e => {
      e.preventDefault();
      const { parentNode: group, nextElementSibling: content } =
        e.currentTarget;

      if (isVisible(content)) {
        this._close(e.currentTarget, group, content);
      } else {
        this._open(e.currentTarget, group, content);
      }
    });

    if (shouldAnimate(this.container)) {
      this.animateCollapsibleRowList = animateCollapsibleRowList(
        this.container
      );
    }
  },

  _open(label, group, content) {
    slideStop(content);
    slideDown(content);
    group.setAttribute("data-open", true);
    label.setAttribute("aria-expanded", true);
    content.setAttribute("aria-hidden", false);
  },

  _close(label, group, content) {
    slideStop(content);
    slideUp(content);
    group.setAttribute("data-open", false);
    label.setAttribute("aria-expanded", false);
    content.setAttribute("aria-hidden", true);
  },

  onBlockSelect({ target }) {
    const label = qs(selectors.itemTrigger, target);
    const { parentNode: group, nextElementSibling: content } = label;

    this._open(label, group, content);
  },

  onUnload() {
    this.clickHandlers();
    this.animateCollapsibleRowList?.destroy();
  },
});
