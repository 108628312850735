import { section } from "@/glow";
import { animateBlogPosts, shouldAnimate } from "@/lib/animation";

section("blog-posts", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateBlogPosts = animateBlogPosts(this.container);
    }
  },

  onUnload() {
    this.animateBlogPosts?.destroy();
  },
});
