import { contains } from "@fluorescent/dom";
import { intersectionWatcher, delayOffset } from "@/lib/animation";
import getMediaQuery from "@/lib/media-queries";

const selectors = {
  introductionItems: ".section-introduction > *",
  image: ".complete-the-look__image-wrapper .image__img",
  product: ".complete-the-look__product",
  products: ".complete-the-look__products",
};

const classes = {
  imageLeft: "complete-the-look--image-left",
};

export default node => {
  const delayItems = [];

  delayItems.push(selectors.introductionItems);

  // Create an array of selectors for the animation elements
  // in the order they should animate in
  if (
    contains(node, classes.imageLeft) ||
    window.matchMedia(getMediaQuery("below-720")).matches
  ) {
    delayItems.push(selectors.image);
    delayItems.push(selectors.products);
    delayItems.push(selectors.product);
  } else {
    delayItems.push(selectors.products);
    delayItems.push(selectors.product);
    delayItems.push(selectors.image);
  }

  // Add the animation delay offset variables
  delayOffset(node, delayItems);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer.destroy();
    },
  };
};
