import { section } from "@/glow";
import { animatePassword, shouldAnimate } from "@/lib/animation";

section("password", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animatePassword = animatePassword(this.container);
    }
  },

  onUnload() {
    this.animatePassword?.destroy();
  },
});
