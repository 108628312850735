import { listen } from "@fluorescent/dom";

const autoPlay = videos => {
  if (!videos.length) return;

  const events = [
    listen(window, "click", () => _handleAutoPlay()),
    listen(window, "touchstart", () => _handleAutoPlay()),
  ];

  // Force autoplay after device interaction if in low power mode
  function _handleAutoPlay() {
    videos.forEach(video => {
      if (!video.playing) {
        video.play();
      }
    });
    events.forEach(unsubscribe => unsubscribe());
  }
};

export default autoPlay;
