import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  media: ".animation--product-media",
};

export default node => {
  // Add the animation delay offset variables
  delayOffset(node, [selectors.media]);

  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
