import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  items: ".animation--section-blocks > *",
};

export default node => {
  delayOffset(node, [selectors.items]);

  const observer = intersectionWatcher(node, true);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
