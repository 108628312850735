import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  sectionBlockItems: ".section-blocks > *",
};

export default node => {
  const observer = intersectionWatcher(node);
  delayOffset(node, [selectors.sectionBlockItems]);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
