import { section } from "@/glow";
import { animatePromoBanner, shouldAnimate } from "@/lib/animation";

section("promo-banner", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animatePromoBanner = animatePromoBanner(this.container);
    }
  },

  onUnload() {
    this.animatePromoBanner?.destroy();
  },
});
