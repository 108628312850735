import { qs, toggle } from "@fluorescent/dom";
import srraf from "srraf";
import debounce from "@/utils/debounce";

// detect support for the behavior property in ScrollOptions
const supportsNativeSmoothScroll =
  "scrollBehavior" in document.documentElement.style;

const backToTop = () => {
  const node = qs("[data-back-to-top]");
  if (!node) return;

  // Handling button visibility
  const pageHeight = window.innerHeight;
  let isVisible = false;

  // Whatch scroll updates, we don't need precision here so we're debouncing
  srraf(({ y }) => debounce(() => _scrollHandler(y))());

  function _scrollHandler(y) {
    // Check if the button visibility should be toggled
    if ((y > pageHeight && !isVisible) || (y < pageHeight && isVisible)) {
      _toggleVisibility();
    }
  }

  function _toggleVisibility() {
    toggle(node, "visible");
    isVisible = !isVisible;
  }

  // Handling button clicks
  const button = qs("[data-back-to-top-button]", node);

  button.addEventListener("click", _buttonClick);

  function _buttonClick() {
    if (supportsNativeSmoothScroll) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }
};

export default backToTop;
