import { intersectionWatcher, delayOffset } from "@/lib/animation";

const selectors = {
  intro: ".animation--section-introduction > *",
  items: ".animation--item",
};

export default node => {
  delayOffset(node, [selectors.intro, selectors.items]);

  const observer = intersectionWatcher(node);

  return {
    destroy() {
      observer?.destroy();
    },
  };
};
