import srraf from "srraf";

const atBreakpointChange = (breakpointToWatch, callback) => {
  const _screenUnderBP = () => {
    const viewportWidth =
      window.innerWidth || document.documentElement.clientWidth;

    return viewportWidth <= breakpointToWatch;
  };

  let screenUnderBP = _screenUnderBP();

  const widthWatcher = srraf(({ vw }) => {
    const currentScreenWidthUnderBP = vw <= breakpointToWatch;

    if (currentScreenWidthUnderBP !== screenUnderBP) {
      screenUnderBP = currentScreenWidthUnderBP;
      return callback();
    }
  });

  const unload = () => {
    widthWatcher.destroy();
  };

  return { unload };
};

export default atBreakpointChange;
