import { section } from "@/glow";
import { animateCollectionListGrid, shouldAnimate } from "@/lib/animation";

section("collection-list-grid", {
  onLoad() {
    if (shouldAnimate(this.container)) {
      this.animateCollectionListGrid = animateCollectionListGrid(
        this.container
      );
    }
  },

  onUnload() {
    this.animateCollectionListGrid?.destroy();
  },
});
