import { qs, qsa } from "@fluorescent/dom";
import { focusFormStatus } from "@/lib/a11y";

import { section } from "@/glow";
import { wrapIframes, wrapTables } from "@/lib/rte";
import SocialShare from "@/lib/social-share";
import { animateArticle, shouldAnimate } from "@/lib/animation";

section("article", {
  onLoad() {
    focusFormStatus(this.container);

    const socialShareContainer = qs(".social-share", this.container);

    if (socialShareContainer) {
      this.socialShare = SocialShare(socialShareContainer);
    }

    wrapIframes(qsa("iframe", this.container));
    wrapTables(qsa("table", this.container));

    if (shouldAnimate(this.container)) {
      this.animateArticle = animateArticle(this.container);
    }
  },

  onUnload() {
    this.socialShare && this.socialShare();
    this.animateArticle?.destroy();
  },
});
